<template>
  <g :fill="color" fill-rule="nonzero">
    <path d="M.293 1.707l18 18a1 1 0 0 0 1.414-1.414l-18-18A1 1 0 1 0 .293 1.707z" />
    <path d="M18.293.293l-18 18a1 1 0 0 0 1.414 1.414l18-18A1 1 0 1 0 18.293.293z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
